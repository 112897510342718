<template>
  <div
    v-if="details && details.measure_storage"
    class="measure-detail-clinician"
  >
    <div class="header">
      <div class="abbr">
        <icon-button class="fa-star" />
        {{ details.measure_storage.abbreviation }}
      </div>
      <button
        v-if="details.audition_enabled"
        type="button"
        class="btn btn-sqare btn-secondary"
        @click="openAudition"
      >
        {{ $t('auditionMeasure') }}
      </button>
    </div>
    <div class="overview">
      <div class="name">
        {{ details.measure_storage.name }}
      </div>
      <div class="boxes">
        <div class="box questions">
          <div class="title">
            questions
          </div>
          <div class="value">
            {{ details.measure_storage.length }}
          </div>
        </div>
        <div class="box default-frequency">
          <div class="title">
            Default frequency
          </div>
          <div class="value">
            {{ details.frequency_reference.text }}
          </div>
        </div>
        <div class="box respondent">
          <div class="title">
            respondent
          </div>
          <div class="value">
            {{ details.measure_storage.respondent }}
          </div>
        </div>
        <div class="box age-range">
          <div class="title">
            age range
          </div>
          <div class="value">
            {{ details.measure_storage.age_range }}
          </div>
        </div>
      </div>
    </div>
    <div class="details">
      <div v-html="details.measure_storage.description_long" />
      <div
        v-if="details.measure_storage.logic_score"
        class="box"
      >
        <h3>{{ $t('scoring') }}</h3>
        <div v-html="details.measure_storage.logic_score" />
      </div>
      <div
        v-if="details.measure_storage.logic_graph"
        class="box"
      >
        <h3>{{ $t('graphing') }}</h3>
        <div v-html="details.measure_storage.logic_graph" />
      </div>
      <div
        v-if="details.measure_storage.response_values"
        class="box"
      >
        <h3>{{ $t('response') }}</h3>
        <div v-html="details.measure_storage.response_values" />
      </div>
      <div
        v-if="details.measure_storage.logic_trigger"
        class="box"
      >
        <h3>{{ $t('triggers') }}</h3>
        <div v-html="details.measure_storage.logic_trigger" />
      </div>
    </div>
    <ModalWrapper
      :template="'audition'"
      :visible="auditionModalOpened"
    />
  </div>
</template>

<script>
import IconButton from '@/components/common/IconButton'
import ModalWrapper from '../../modal/modalWrapper'
import { mapGetters, mapActions } from 'vuex'
import { ACTION_OPEN_AUDITION_ENHANCE_MODAL } from '@/store/modules/applicationState/constants'

export default {
  name: 'MeasureDetail',
  components: {
    ModalWrapper,
    IconButton
  },
  props: {
    id: {
      default: null,
      type: String
    },
    starred: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      auditionModalOpened: false,
      searchMeasure: ''
    }
  },
  computed: {
    ...mapGetters({
      details: 'measureLibraryDetail'
    })
  },
  watch: {
    id () {
      this.getMeasureDetail()
    }
  },
  created () {
    this.getMeasureDetail()
  },
  methods: {
    ...mapActions({
      openAuditionEnhance: ACTION_OPEN_AUDITION_ENHANCE_MODAL
    }),
    openAudition () {
      if (!this.details.audition_enabled) {
        return false
      }

      if (this.details.audition_configuration && this.details.audition_configuration.enhance) {
        this.openAuditionEnhance(this.details)
        return true
      }

      this.$router.push({ name: 'AuditionMeasureQuestionnaire', params: { measureId: this.details.id } })
    },
    getMeasureDetail () {
      const loader = this.$loading.show()
      this.$store.dispatch('GET_MEASURE_LIBRARY_DETAIL', this.id).catch(() => {
        this.$router.push({ name: 'PageNotFound' })
      }).finally(() => {
        this.$emit('loaded')
        loader.hide()
      })
    }
  }
}
</script>
