<template>
  <div class="measure-library-clinician">
    <ClinicianLayoutNav
      :tabs="tabs"
      :show-search="true"
      :placeholder="$t('searchMeasures')"
      @searching="(s) => searchMeasure = s"
    />
    <vue-good-table
      :ref="tableReference"
      mode="remote"
      :is-loading="isLoading"
      :total-rows="totalRecords"
      style-class="vgt-table"
      :columns="columns"
      :rows="measureLibrary"
      :fixed-header="true"
      :max-height="maxHeight"
      :row-style-class="activeClass"
      :search-options="{
        enabled: true,
        externalQuery: searchMeasure
      }"
      :pagination-options="{
        enabled: true
      }"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-cell-click="onCellClick"
      @on-search="onSearchTermChange"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.label == $t('measureLibraryTable.starred')">
          <i class="fal fa-star" />
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.label == $t('measureLibraryTable.starred')">
          <icon-button
            :id="'measure-id-' + props.row.id"
            class="fa-star"
            :status="!!props.formattedRow[props.column.field]"
            @changed="toggleStarred(props.row)"
          />
        </span>
        <span v-else-if="props.column.label == $t('measureLibraryTable.name')">
          <b>{{ props.row.abbreviation }}</b>: {{ props.row.description }}
          <b
            v-if="props.row.must_activate && !props.row.activated"
            class="discontinued-text"
          >{{ $t('measure_list.must_activate_text') }}</b>
          <div v-else-if="props.row.discontinued">
            <MeasureDiscontinuedBadge
              :discontinued-last-change="props.row.discontinued_last_change"
              :replacing-measure="props.row.replacing_measure"
            />
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div
        v-show="isLoading"
        slot="loadingContent"
      >
        <LoadingSpinner />
      </div>
      <div
        v-show="!isLoading"
        slot="emptystate"
      >
        {{ $t('noResultsFound') }}
      </div>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <PaginationFooter
          v-show="paginationEnabled"
          :total="props.total"
          :server-params="serverParams"
          :per-page-changed="props.perPageChanged"
          :page-changed="onPageChange"
        />
      </template>
    </vue-good-table>
    <detail-view
      v-if="showDetail"
      body-class="measure-detail-view"
      @close="showDetail = false"
    >
      <template slot="content">
        <measure-detail
          :id="`${detailMeasure.id}`"
          @loaded="toggleSelected"
        />
      </template>
    </detail-view>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import MeasureDiscontinuedBadge from '@/components/clinician/measures/MeasureDiscontinuedBadge'
import { mapGetters } from 'vuex'
import IconButton from '@/components/common/IconButton'
import DetailView from '@/components/common/DetailView'
import MeasureDetail from '@/components/clinician/measures/MeasureDetail'
import ClinicianLayoutNav from '@/components/common/ClinicianLayoutNav'
import { MeasuresClinician } from '@/mixins/TableColumnsHelper'
import PaginationFooter from '@/components/common/PaginationFooter'
import { RemoteTable } from '@/mixins/remoteTables/RemoteTable'
import { GET_MEASURE_LIBRARY } from '@/store/modules/measures/constants.js'

const CELL_DETAIL_IGNORED = ['starred']

export default {
  name: 'MeasuresClinician',
  components: {
    VueGoodTable,
    LoadingSpinner,
    IconButton,
    DetailView,
    MeasureDetail,
    ClinicianLayoutNav,
    MeasureDiscontinuedBadge,
    PaginationFooter
  },
  mixins: [MeasuresClinician, RemoteTable],
  data () {
    return {
      tableReference: 'measuresClinician',
      tableMaxHeight: 135,
      searchMeasure: '',
      action: GET_MEASURE_LIBRARY,
      defaultSortOrder: 'asc',
      defaultSortField: 'name',
      detailMeasure: {},
      showDetail: false,
      options: {
        sort: {
          enabled: true
        }
      },
      tabs: [{
        label: 'Measures',
        href: 'MeasuresClinician'
      }]
    }
  },
  computed: {
    ...mapGetters({
      measureLibrary: 'measureLibrary'
    })
  },
  created () {
    this.loadItems()
  },
  mounted () {
    if (this.measureLibrary.length) {
      this.initDetail()
    }
  },
  methods: {
    initDetail () {
      const query = Object.assign({}, this.$route.query)

      if (!query.detailId) {
        return
      }
      const detailId = query.detailId
      delete query.detailId
      this.$router.replace({ query })
      if (detailId) {
        const measure = this.measureLibrary.find(m => Number(m.id) === Number(detailId))
        if (measure) {
          this.detailMeasure = measure
          this.showDetail = true
          setTimeout(() => {
            this.$scrollTo('.row-active', { container: '.vgt-responsive', offset: -4 })
          }, 300)
        }
      }
    },
    activeClass (row) {
      if (row.selected) {
        return 'row-active'
      }

      if (row.must_activate && !row.activated) {
        return 'must_activate'
      }

      return row.discontinued ? 'discontinued' : ''
    },
    onCellClick (params) {
      if (!CELL_DETAIL_IGNORED.includes(params.column.field)) {
        this.detailMeasure = params.row
        this.showDetail = true
      }
    },
    toggleSelected () {
      this.$store.dispatch('TOGGLE_LIBRARY_SELECTED', this.detailMeasure)
    },
    toggleStarred (item) {
      this.$store.dispatch('TOGGLE_LIBRARY_STARRED', item)
    },
    loadedItemsEvent () {
      this.showDetail = false
    }
  }
}
</script>
